// import logo from './logo.svg';
import { ethers } from "ethers";
import React from "react";
import './App.css';
import util_NonFungibleTerhar from "./utils/NonFungibleTerhar.json"

function App() {
  const [currAccount, setCurrentAccount] = React.useState("");
  const [statusMessage, setStatusMessage] = React.useState("");
  const [openSeaLnk, setOpenSeaLnk] = React.useState("");
  const [mining, setMining] = React.useState(false);
  const contractAddress = "0xFD63E56Ede722A3fAcb7B6E37357Da6908668c10"

  const checkIfWalletIsConnected = () => {
    const { ethereum } = window;
    if(!ethereum){
      console.log("Install metamask or use an ethereum browser")
      // setStatusMessage("Your browser needs metamask or some ethereum plugin.")
      return
    } else {
      console.log("Ethereum object found: " + ethereum)
    }

    if(ethereum.networkVersion === "4"){
console.log("Using Rinkeby network, looks good")
    } else {
      console.log("Using the wrong network! Switch to Rinkeby.")
      // setStatusMessage("Switch your ethereum network to Rinkeby.")
    }

    ethereum.request({ method: 'eth_accounts' })
    .then(accounts => {
      if(accounts.length !== 0) {
        const account = accounts[0];
        console.log("found authorized account: ", account)

        setCurrentAccount(account);
        setupEventListener()
      } else {
        console.log("No authorized account found")
      }
    })
  }

  const connectWallet = () => {
    const { ethereum } = window;
    if(!ethereum) {
      setStatusMessage("Your browser needs metamask or some ethereum plugin.")
      return
    }
    ethereum.request({ method: 'eth_requestAccounts' })
    .then(accounts => {
      console.log("Connected", accounts[0])

      if(ethereum.networkVersion === "4"){
        console.log("Using Rinkeby network, looks good")
        setStatusMessage("Your browser is ready and you're known by your public key! Sup.")
      } else {
        console.log("Using the wrong network! Switch to Rinkeby.")
        setStatusMessage("Your wallet looks good, please switch your ethereum network to Rinkeby.")
      }
      setCurrentAccount(accounts[0])
      setupEventListener()
    })
    .catch(err => console.log(err));
  }

  React.useEffect(() => {
    checkIfWalletIsConnected()
  }  )

  const setupEventListener = async () => {
    // Most of this looks the same as our function askContractToMintNft
    try {
      const { ethereum } = window;

      if (ethereum) {
        // Same stuff again
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(contractAddress, util_NonFungibleTerhar.abi, signer);

        connectedContract.on("NewNFTerharMinted", (from, tokenId) => {
          console.log(from, tokenId.toNumber())
          setStatusMessage(`Hey there! We've minted your NFT and sent it to your wallet. It may be blank right now. It can take a max of 10 min to show up on OpenSea.`)
          setOpenSeaLnk("https://testnets.opensea.io/assets/" + contractAddress + "/" + tokenId)
          setMining(false)
        });

        console.log("Setup event listener!")

      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error)
    }
  }

  const askContractToMintNft = async () => {
    
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(contractAddress, util_NonFungibleTerhar.abi, signer);

        console.log("Going to pop wallet now to pay gas...")
        let nftTxn = await connectedContract.makeNFTerhar();
        setMining(true)
        console.log("Mining...please wait.")
        await nftTxn.wait();
        
        console.log(`Mined, see transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}`);

      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="mainContainer">

      <div className="dataContainer">
        <div className="header">
          NonFungibleTerhar presents: 
          <br />
          🎨 Nonsense 🎨 
        </div>

        <div className="bio">
          Mint yourself a shiny new NFT! 
        </div>

        <div className="cta">
          {statusMessage ? statusMessage : (
            <span>Let's get started</span>
            )}
        </div>
        
        {currAccount ? (
          <div className="cta">
            <button className="mintButton" onClick={askContractToMintNft}>
              Mint new NFTerhar Nonsense
            </button>
          </div>
          ) : (
          <div className="cta">
            <button className="mintButton" onClick={connectWallet}>
              Connect wallet
            </button>
          </div>
        )}

        {openSeaLnk ? (
          <div className="cta">
            <h2>Your NFT has been minted!</h2>
            It may take up to 10 minutes to appear on OpenSea.<br />
            <br />
            <a href={openSeaLnk} target="_blank" rel="noreferrer">
              <button className="openSeaLnk">
                See your NFT on OpenSea
              </button>
            </a>
          </div>
        ) : (
          <div>
          </div>
        )
        }

        {mining ? (
          <div className="cta">
            Mining, please wait.
            <br />
            <img src="gear.svg" alt="spinning gear." />
            <br />
          </div>
        ) : ( 
          <br />
        )
        }

      </div>

      <div className="footer">
        <a href="https://testnets.opensea.io/collection/squarenft-wow7lyt180" target="_blank" rel="noreferrer">
          <button className="footButton">
            Check out this NFT collection
          </button>
        </a>
        {process.env.REACT_APP_GIT_SHA}
      </div>
    </div>
  );
}

export default App;
